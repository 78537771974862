// General helpers

//Visibility
.hide-me {
	display: none;
}

//Spacing
.contain-all {
	* {
		max-width: 100%;
	}
}