@import 'node_modules/bootstrap/scss/_functions.scss';
@import 'node_modules/bootstrap/scss/mixins.scss';
@import '../mixins/*';
@import '../config/bootstrap.scss';
@import '../config/variables';
@import '../config/_functions';
@import '../config/_import';

// @group admin styles
$rem_value: 16px;

$login_bg: $black;
$login_color: $white;
$form_accent: color('red');

html,
body {
    font-size: $rem_value;
}

body.login-action-login {
    font-family: $font-family-sans-serif !important;

    display: flex;

    align-items: center;
    justify-content: center;

    background: $login_bg;

    #login {
        font-family: $headings-font-family;
        color: $login_color;

        h1 {
            a {
                width: 100%;
                height: 39px;

                background-image: url(/assets/images/logo.svg);
                background-size: contain;
                background-position: center center;

                margin: 0;
            }
        }

        p.message {
            border-left: 4px solid division-color('cts');
            background: rgba(255, 255, 255, 0.1);
            margin-top: 10px;
        }

        #nav,
        #backtoblog {
            font-size: 1rem;
            text-align: center;
            // padding: 0;
            a {
                // color: #fff;
                @include on-action() {
                    color: $login_color;
                }
            }
        }

        form#loginform {
            background: rgba(#fff, .1);
            
            label {
                font-size: 1rem;
                color: $login_color;
            }

            input[type="text"],
            input[type="password"] {
                @include on-action() {
                    outline: none;
                    box-shadow: 0px 0px 4px rgba(#fff, .5);
                    border-color: initial;
                }
            }

            input[type="checkbox"] {
                &:before {
                    color: $form_accent;
                }
                @include on-action() {
                    border-color: $login_color;
                    box-shadow: 0px 0px 4px rgba(#fff, .5);
                }
            }

            input[type="submit"] {
                text-transform: uppercase;
                text-shadow: none;
                box-shadow: none;

                background: $red;
                border-color: $red;

                border-radius: $border-radius;

                font-size: 1.25rem;
            }
        }
	}
	
	#login_error{
		color: #000;
		margin-top: 20px;
	}
}