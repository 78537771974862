//Define ANY custom variables used here otherwise Webpack will not compile
//Variables must be false by default.

// @FontFace custom fonts for this project - (font,url,type,weight,style)
//('Font Name', '../fontpath.ttf', 'truetype', normal, normal); // concatenate with ','
$customfonts: false;
//Array of fonts I.E $googlefonts: 'Arial', 'Roboto';
$googlefonts: 'Barlow', 'Barlow+Condensed';

@each $color, $hex in $grays {
    .text-gray-#{$color} { color: $hex; }
    .bg-gray-#{$color} { background: $hex; }
}

@each $color, $hex in $division-colors {
    .text-#{$color} { color: $hex !important; }
    .bg-#{$color} { background: $hex !important; }
}