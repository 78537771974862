/// On-action Mixin
/// @description Combine hover, active and focus states into one css declaration
/// @group _mixins
/// @author Lew Krehnbrink
@mixin on-action() {
	&:hover,
	&:active,
	&:focus {
		outline: none;
		@content;
	}
}

// @hover-border
@mixin hover-border($selector: 'span', $color: $danger, $height: 4px) {

    #{$selector} {
        background-size: 0px $height;
        background-position: 0px 100%;
        transition: background-size 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
        padding: 2px 0px 1px;
        background-repeat: no-repeat;
        background-image: linear-gradient(to right, $color 0%, $color 100%);
        overflow: visible;
    }

    @include on-action() {
        text-decoration: none;
        #{$selector} {
            text-decoration: none;
            background-size: 100% $height;
        }
    }
}
//test